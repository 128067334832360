import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { COLORS, LINKS, TYPOGRAPHY } from '../../../constants';
import { UiLink } from '../../../ui/UiLink';

interface ForWhom {
  buttonText: string;
  description: string;
  title: string;
}

interface CoreBusinessProps {
  data: {
    title: string;
    forBrandsBlock: ForWhom;
    forRadioStationsBlock: ForWhom;
  };
}

const CoreBusiness: FC<CoreBusinessProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <SectionTitle>{data.title}</SectionTitle>
        <WhomList>
          <WhomItem>
            <WhomTitle>{data.forRadioStationsBlock.title}</WhomTitle>
            <WhomDescription>
              {data.forRadioStationsBlock.description}
            </WhomDescription>
            <WhomLink
              text={data.forRadioStationsBlock.buttonText}
              href={LINKS.forRadioStations}
            />
          </WhomItem>
          <WhomItem>
            <WhomTitle>{data.forBrandsBlock.title}</WhomTitle>
            <WhomDescription>{data.forBrandsBlock.description}</WhomDescription>
            <WhomLink
              text={data.forBrandsBlock.buttonText}
              href={LINKS.forBrands}
            />
          </WhomItem>
        </WhomList>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.black2};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 154px;
  @media (max-width: 768px) {
    padding-top: 80px;
    padding-bottom: 100px;
  }
`;

const SectionTitle = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.white};
  margin-bottom: 60px;
  @media (max-width: 900px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    margin-bottom: 40px;
    font-size: 48px;
    line-height: 55px;
    text-align: left;
  }
  @media (max-width: 600px) {
    font-size: 36px;
    line-height: 41px;
  }
  @media (max-width: 500px) {
    max-width: 260px;
  }
`;

const WhomList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const WhomItem = styled.li`
  flex-grow: 1;
  @media (max-width: 900px) {
    margin-bottom: 95px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 65px;
  }
`;

const WhomTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.white};
  margin-bottom: 30px;
`;

const WhomDescription = styled.p`
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  margin-bottom: 30px;
  max-width: 370px;
  @media (max-width: 900px) {
    max-width: none;
  }
  @media (max-width: 600px) {
    max-width: 310px;
  }
`;

const WhomLink = styled(UiLink)`
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export default CoreBusiness;
