import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface AboutUsProps {
  data: {
    firstSection: {
      description: string;
      image: {
        localFile: any;
      };
    };
    secondSection: {
      firstDescription: string;
      secondDescription: string;
      image: {
        localFile: any;
      };
    };
  };
}

const AboutUs: FC<AboutUsProps> = ({ data }) => {
  return (
    <Root>
      <h2 className="visually-hidden">About Us</h2>
      <FirstSection>
        <FirstSectionContainer>
          <FirstSectionLeft>
            <FirstSectionText>{data.firstSection.description}</FirstSectionText>
            <WhereMadeText>
              Made in
              <br />
              Switzerland
            </WhereMadeText>
          </FirstSectionLeft>
          <FirstSectionRight>
            <FirstSectionRightImage
              style={{
                backgroundImage: `url(${data.firstSection.image.localFile.publicURL})`,
              }}
            />
          </FirstSectionRight>
        </FirstSectionContainer>
      </FirstSection>
      <SecondSection>
        <SecondSectionContainer>
          <SecondSectionImageWrap>
            <SecondSectionImage
              localFile={data.secondSection.image.localFile}
              alt="Meeting"
            />
          </SecondSectionImageWrap>
          <SecondSectionDescriptionWrap>
            <SecondSectionText>
              {data.secondSection.firstDescription}
            </SecondSectionText>
            <SecondSectionText>
              {data.secondSection.secondDescription}
            </SecondSectionText>
          </SecondSectionDescriptionWrap>
        </SecondSectionContainer>
      </SecondSection>
    </Root>
  );
};

const Root = styled.section`
  overflow: hidden;
`;

const FirstSection = styled.div`
  background-color: ${COLORS.red};
`;

const FirstSectionContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  display: flex;
  min-height: 612px;
  overflow: hidden;
  @media (max-width: 1200px) {
    min-height: 500px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    min-height: auto;
  }
`;

const FirstSectionLeft = styled.div`
  width: 45%;
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
    min-height: 470px;
  }
`;

const FirstSectionRight = styled.div`
  flex-grow: 1;
  position: relative;
  min-height: 612px;
`;

const FirstSectionRightImage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* background-color: black; */
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    width: 768px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
`;

const FirstSectionText = styled.p`
  position: absolute;
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  left: 17.3%;
  top: 160px;
  max-width: 311px;

  @media (max-width: 1300px) {
    left: 50px;
    top: 100px;
  }
  @media (max-width: 1024px) {
    left: 32px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 36px;
    top: 80px;
    right: 32px;
    max-width: none;
  }
  @media (max-width: 500px) {
    left: 16px;
    right: 46px;
    font-size: 16px;
    line-height: 24px;
    top: 45%;
    transform: translateY(-50%);
  }
`;

const WhereMadeText = styled.p`
  font-weight: 600;
  font-size: 6.73vw;
  line-height: 0.95;
  color: ${COLORS.white};
  position: absolute;
  bottom: 0;
  left: 0;

  @media (min-width: 1440px) {
    font-size: 93px;
    /* line-height: 80px; */
    bottom: 0;
    left: 0;
  }
  @media (max-width: 768px) {
    font-size: 14.9vw;
    bottom: 0;
    left: 0;
  }
`;

const SecondSection = styled.div`
  background-color: ${COLORS.white};
`;

const SecondSectionContainer = styled(Container)`
  padding: 100px 50px 100px 0;
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 80px 32px 0;
  }
  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 70px;
  }
`;

const SecondSectionDescriptionWrap = styled.div`
  max-width: 500px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1024px) {
    max-width: 600px;
    padding-top: 0;
    margin-bottom: 80px;
  }
  @media (max-width: 767px) {
    margin-bottom: 70px;
  }
  @media (max-width: 400px) {
    max-width: 310px;
  }
`;

const SecondSectionText = styled.p`
  ${TYPOGRAPHY.sectionText};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SecondSectionImageWrap = styled.div`
  position: relative;
  margin-right: 102px;

  @media (max-width: 1300px) {
    margin-right: 50px;
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: -32px;
  }

  @media (max-width: 767px) {
    margin-left: -16px;
    min-height: 350px;
  }
`;

const SecondSectionImage = styled(Image)`
  object-fit: contain;
  height: 100%;
  width: auto;
  /* @media (max-width: 1024px) {
    width: calc(100% + 64px);
  }
  @media (max-width: 767px) {
    width: 768px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  } */
`;

export default AboutUs;
