import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { AboutUs } from '../pageComponents/MainPage/AboutUs';
import { Advantages } from '../pageComponents/MainPage/Advantages';
import { Clients } from '../pageComponents/MainPage/Clients';
import { CoreBusiness } from '../pageComponents/MainPage/CoreBusiness';
import { Hero } from '../pageComponents/MainPage/Hero';
import { NewFeature } from '../components/NewFeature';
import { OnePlatform } from '../pageComponents/MainPage/OnePlatform';
import { ReleaseNow } from '../components/ReleaseNow';
import { CasesSectionAdvanced } from '../pageComponents/MainPage/CasesSectionAdvanced';
import { Seo } from '../components/Seo';

const FrontPageTemplate = ({ pageContext }: any) => {
  const {
    heroSection,
    onePlatformSection,
    coreBusinessSection,
    advantagesSection,
    aboutUs,
  } = pageContext.homeFields;
  const newFeature = pageContext.newFeature;
  const releaseNow = pageContext.releaseNow;
  const seo = pageContext.seo;
  const title = pageContext.title;

  return (
    <MainLayout>
      <Seo
        title={seo.seoTitle ? seo.seoTitle : title}
        description={seo.seoDescription ? seo.seoDescription : ''}
      />

      <Hero data={heroSection} />
      <OnePlatform data={onePlatformSection} />
      <CoreBusiness data={coreBusinessSection} />
      <CasesSectionAdvanced />
      <Clients />
      <NewFeature data={newFeature} />
      <AboutUs data={aboutUs} />
      <Advantages data={advantagesSection} />
      <ReleaseNow data={releaseNow} />
    </MainLayout>
  );
};
export default FrontPageTemplate;
