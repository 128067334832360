import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS } from '../../../constants';
import { useClientsImage } from '../../../hooks/useClientsImage';

const Clients: FC = () => {
  const clients = useClientsImage();

  return (
    <Root>
      <StyledContainer>
        <ClientsList $itemCount={clients.length}>
          {clients.map(item => {
            return (
              <ClientsItem key={item.id}>
                <Image
                  localFile={item.clientsImage.image.localFile}
                  alt={item.title}
                />
              </ClientsItem>
            );
          })}
        </ClientsList>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.white};
`;

const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 30px;
    overflow: auto;
  }
`;

const ClientsList = styled.ul<{ $itemCount: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    min-width: ${({ $itemCount }) => `${$itemCount * 120}px`};
  }
`;

const ClientsItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-width: 120px;
  margin: 20px;
  @media (max-width: 768px) {
    min-width: auto;
  }
`;

export default Clients;
