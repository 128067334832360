import React, { FC } from 'react';
import styled from 'styled-components';
import { Container } from '../../../components/Container';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { AdvantagesCard } from './components/AdvantagesCard';

export interface CardData {
  title: string;
  firstDescription: string;
  secondDescription: string;
  image: {
    localFile: any;
  };
}

interface AdvantagesProps {
  data: {
    title: string;
    firstCard: CardData;
    secondCard: CardData;
    thirdCard: CardData;
  };
}

const Advantages: FC<AdvantagesProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <SectionTitle>{data.title}</SectionTitle>
        <AdvantagesList>
          <StyledAdvantagesCard data={data.firstCard} />
          <StyledAdvantagesCard data={data.secondCard} />
          <StyledAdvantagesCard data={data.thirdCard} />
        </AdvantagesList>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray};
`;

const StyledContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 60px;
  @media (max-width: 1024px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (max-width: 768px) {
    padding-bottom: 58px;
  }
`;

const SectionTitle = styled.h2`
  ${TYPOGRAPHY.sectionTitle}
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }
  @media (max-width: 650px) {
    text-align: left;
  }
  @media (max-width: 400px) {
    max-width: 300px;
  }
`;

const AdvantagesList = styled.ul`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

const StyledAdvantagesCard = styled(AdvantagesCard)`
  flex: 1;
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1150px) {
    margin-right: 0;
    margin-bottom: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 650px) {
    margin-bottom: 20px;
  }
`;

export default Advantages;
