import React, { FC } from 'react';
import styled from 'styled-components';
import CircleLines from '../../../assets/images/svg/CircleLines';
import { Container } from '../../../components/Container';
import { Image } from '../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../constants';

interface OnePlatformProps {
  data: {
    firstDescription: string;
    secondDescription: string;
    title: string;
    image: {
      localFile: any;
    };
  };
}

const OnePlatform: FC<OnePlatformProps> = ({ data }) => {
  return (
    <Root>
      <StyledContainer>
        <NotebookWrapper>
          <StyledCirleLines />
          <NotebookImage localFile={data.image.localFile} alt="Notebook" />
        </NotebookWrapper>
        <DescriptionWrapper>
          <Title dangerouslySetInnerHTML={{ __html: data.title }} />
          <DescriptionText>{data.firstDescription}</DescriptionText>
          <DescriptionText>{data.secondDescription}</DescriptionText>
        </DescriptionWrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  position: relative;
  background-color: ${COLORS.white};

  &::before {
    content: '';
    position: absolute;
    height: calc(100% + 1px);
    width: 100vw;
    top: -1px;
    right: 72.5vw;
    background-color: ${COLORS.red};
  }
  @media (max-width: 1024px) {
    overflow: hidden;

    &::before {
      content: none;
    }
    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 190px;
      top: 0;
      right: 0;
      background-color: ${COLORS.red};
    }
  }

  @media (max-width: 768px) {
    &::after {
      width: 100vw;
      height: 162px;
      left: 0;
      top: auto;
      bottom: 0;
    }
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 110px;
  padding-bottom: 200px;
  padding-left: 50px;
  padding-right: 17.9%;

  @media (min-width: 1540px) {
    padding-right: 259px;
  }
  /* @media (max-width: 1439px) {
    padding-right: 7.5%;
  } */
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1200px) {
    justify-content: flex-end;
  }
  @media (max-width: 1024px) {
    padding: 60px 32px;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 30px;
    padding-top: 30px;
    flex-direction: column-reverse;
  }
`;

const NotebookWrapper = styled.div`
  display: inline-flex;
  position: relative;
  padding-top: 22px;
  @media (min-width: 1200px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1200px) {
    position: absolute;
    top: 110px;
    left: -110px;
  }
  @media (max-width: 1024px) {
    z-index: 1;
    left: auto;
    right: -60px;
    top: 130px;
  }
  @media (max-width: 768px) {
    position: relative;
    top: auto;
    right: auto;
    align-self: center;
  }
`;

const NotebookImage = styled(Image)`
  max-width: 577px;
  @media (max-width: 1024px) {
    width: 450px;
    height: auto;
  }
  @media (max-width: 900px) {
    width: 345px;
  }
`;

const StyledCirleLines = styled(CircleLines)`
  position: absolute;
  left: 32%;
  bottom: -20px;
  @media (max-width: 1024px) {
    bottom: 100px;
    left: -50px;
    width: 360px;
    height: auto;
  }
  @media (max-width: 900px) {
    bottom: auto;
    top: -100px;
    width: 260px;
  }
  @media (max-width: 768px) {
    width: 226px;
    left: auto;
    top: -105px;
    right: -75px;
  }
`;

const DescriptionWrapper = styled.div`
  max-width: 450px;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    margin-bottom: 50px;
    max-width: none;
  }
`;

const Title = styled.div`
  h1,
  h2,
  p {
    ${TYPOGRAPHY.sectionTitle};
    strong {
      color: ${COLORS.red};
    }
  }
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const DescriptionText = styled.p`
  ${TYPOGRAPHY.sectionText};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export default OnePlatform;
