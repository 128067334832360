import React, { FC } from 'react';
import styled from 'styled-components';
import { Image } from '../../../../../components/Image';
import { COLORS, TYPOGRAPHY } from '../../../../../constants';
import { CardData } from '../../Advantages';

interface AdvantagesCardProps {
  data: CardData;
  className?: string;
}

const AdvantagesCard: FC<AdvantagesCardProps> = ({ data, className }) => {
  return (
    <Root className={className}>
      <Wrapper>
        <Title>{data.title}</Title>
        <ImageWrap>
          <Image localFile={data.image.localFile} alt={data.title} />
        </ImageWrap>
        <DescriptionWrapper>
          <DescriptionText>{data.firstDescription}</DescriptionText>
          <DescriptionText>{data.secondDescription}</DescriptionText>
        </DescriptionWrapper>
      </Wrapper>
    </Root>
  );
};

const Root = styled.li`
  background-color: ${COLORS.white};
  border-radius: 15px;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 46px 35px 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  /* @media (max-width: 1150px) {
    padding-left: 300px;
    padding-bottom: 50px;
  } */
  @media (max-width: 1150px) {
    padding-left: 240px;
  }
  @media (max-width: 900px) {
    padding-left: 240px;
  }
  @media (max-width: 650px) {
    padding: 40px 26px;
  }
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.black3};
  margin-bottom: 45px;
  text-align: center;
  @media (max-width: 1150px) {
    text-align: left;
    margin-bottom: 25px;
    align-self: flex-start;
  }
  @media (max-width: 650px) {
    align-self: center;
    text-align: center;
    margin-bottom: 45px;
  }
`;

const ImageWrap = styled.div`
  margin: 0 auto;
  margin-bottom: 45px;
  border-radius: 16px;
  overflow: hidden;
  img {
    border-radius: 16px;
  }
  /* @media (max-width: 1150px) {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
  } */

  @media (max-width: 1150px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 23px;
    img {
      max-height: 145px;
      max-width: 145px !important;
    }
  }

  @media (max-width: 900px) {
    left: 23px;
    img {
      max-height: 145px;
      max-width: 145px !important;
    }
  }
  @media (max-width: 650px) {
    position: static;
    transform: none;
    img {
      max-height: 181px;
      max-width: none !important;
    }
  }
`;

const DescriptionWrapper = styled.div`
  width: 100%;
  align-self: flex-start;
`;

const DescriptionText = styled.p`
  ${TYPOGRAPHY.sectionText}
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 1150px) {
    margin-bottom: 15px;
  }
  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 21px;
  }
  @media (max-width: 650px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export default AdvantagesCard;
