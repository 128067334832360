import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from '../../../components/Container';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { CaseData, useCases } from '../../../hooks/useCases';
import { Image } from '../../../components/Image';
import { Navigation, Pagination, Virtual } from 'swiper';

import 'swiper/css';

const CasesSectionAdvanced: FC = () => {
  const casesData = useCases();
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const prevBtnRef = useRef<HTMLButtonElement>(null);
  const nextBtnRef = useRef<HTMLButtonElement>(null);
  const casePaginationRef = useRef<HTMLParagraphElement>(null);
  const [activeSlide, setActiveSlide] = useState<CaseData>();

  return (
    <Root id="cases">
      <StyledContainer>
        <Title>Some of our cases</Title>
        <Swiper
          modules={[Virtual, Navigation, Pagination]}
          breakpoints={{
            1200: {
              slidesPerView: 1.4,
              spaceBetween: 35,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
          }}
          centeredSlides
          pagination={{
            type: 'custom',
            el: casePaginationRef.current,
            renderCustom: (swiper, current, total) => `${current}/${total}`,
          }}
          onSlideChange={swiper => {
            setActiveSlide(casesData[swiper.activeIndex]);
          }}
          onSwiper={swiper => {
            setActiveSlide(casesData[swiper.activeIndex]);
            setSwiperRef(swiper);
          }}
          navigation={{
            prevEl: prevBtnRef.current!,
            nextEl: nextBtnRef.current!,
          }}
        >
          {casesData.map((item, index) => {
            return (
              <SwiperSlide key={item.id} virtualIndex={index}>
                {({ isActive }) => (
                  <CaseItemWrap $isActive={isActive}>
                    <CaseInfo>
                      <CaseTitle>{item?.title}</CaseTitle>
                      <CaseDescription>
                        {item?.fields.description}
                      </CaseDescription>
                      {item?.fields.link && (
                        <CaseLink href={item.fields.link} target="_blank">
                          See more
                        </CaseLink>
                      )}
                    </CaseInfo>
                    <StyledImage
                      localFile={item.fields.image.localFile}
                      alt={item.title}
                    />
                  </CaseItemWrap>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
        <InfoNavigation>
          <NavigationArrowsWrap>
            <PrevNavigationBtn ref={prevBtnRef} />
            <CasePagination ref={casePaginationRef} />
            <NavigationBtn ref={nextBtnRef} />
          </NavigationArrowsWrap>
        </InfoNavigation>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.lightGray};
`;

const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 100px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  @media (max-width: 1200px) {
    padding-bottom: 100px;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 39px;
    padding-bottom: 50px;
  }
`;

const StyledImage = styled(Image)`
  max-width: 267px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.sectionTitle};
  color: ${COLORS.black3};
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: 600px) {
    text-align: left;
    margin-bottom: 40px;
  }
`;

const InfoNavigation = styled.div`
  width: 100%;
  z-index: 3;

  /* @media (max-width: 1300px) {
    right: 50px;
  } */
  /* @media (max-width: 1200px) {
    bottom: 130px;
  } */
  /* @media (max-width: 1024px) {
    max-width: 285px;
    right: 32px;
  } */
  /* @media (max-width: 767px) {
    right: 16px
    display: none;
  } */
  /* @media (max-width: 600px) {
    bottom: 70px;
  } */
`;

const CaseInfo = styled.div`
  background-color: ${COLORS.red};
  padding: 60px 50px 70px;
  position: relative;
  border-radius: 16px;
  /* margin-bottom: 40px; */
  width: 100%;
  @media (max-width: 1024px) {
    padding: 40px 22px 56px;
    /* margin-bottom: 35px; */
  }
  @media (max-width: 600px) {
    padding-top: 35px;
    padding-left: 30px;
    padding-bottom: 40px;
    padding-right: 33px;
    z-index: 5 !important;
    /* max-height: 214px; */
  }
`;

const CaseTitle = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: ${COLORS.white};
  text-transform: uppercase;
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const CaseDescription = styled.p`
  ${TYPOGRAPHY.sectionText};
  color: ${COLORS.white};
  margin-bottom: 30px;
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }
  @media (max-width: 600px) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 3;
    line-clamp: 3; */
    -webkit-box-orient: vertical;
  }
`;

const CaseLink = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${COLORS.white};
  border: 1px solid ${COLORS.white};
  padding: 10px 27px;
  border-radius: 5px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

const CasePagination = styled.p`
  /* position: absolute;
  bottom: 45px;
  right: 45px; */
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  font-weight: 300;
  color: ${COLORS.darkGray2};
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 36px;
    right: 17px;
    bottom: 24px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 32px;
    bottom: 15px;
  }
`;

const NavigationArrowsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavigationBtn = styled.button`
  cursor: pointer;
  width: 64px;
  height: 23px;
  background: url('/images/arrow.svg') no-repeat center;
  background-size: 64px 23px;
  padding: 20px;
  box-sizing: content-box;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    width: 43px;
    height: 16px;
    background-size: 43px 16px;
    padding: 16px;
  }
`;

const PrevNavigationBtn = styled(NavigationBtn)`
  transform: rotate(180deg);
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.5;
  }
`;

const CaseItemWrap = styled.div<{ $isActive: boolean }>`
  display: grid;
  grid-template-columns: 1fr 250px;
  align-items: center;
  justify-content: center;
  padding: 40px;
  position: relative;
  background-color: ${COLORS.white};
  border-radius: 15px;
  z-index: 1;
  /* &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${COLORS.white};
    transition: transform 0.3s ease;
    transform-origin: top;
    transform: ${({ $isActive }) => ($isActive ? 'scaleY(1)' : 'scaleY(0.74)')};
  } */
  .gatsby-image-wrapper {
    /* transform-origin: top; */
    /* transition: opacity 0.3s ease;
    opacity: ${({ $isActive }) => ($isActive ? '1' : '0.7')}; */
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    /* padding: 20px;
    &::before {
      transform: scaleY(1);
    }
    .gatsby-image-wrapper {
      width: 200px;
      transform: scaleY(1);
    } */
  }
`;

export default CasesSectionAdvanced;
