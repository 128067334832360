import { graphql, useStaticQuery } from 'gatsby';

export interface ClientsImage {
  id: string;
  title: string;
  clientsImage: {
    image: {
      id: string;
      sourceUrl: string;
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useClientsImage(): ClientsImage[] {
  const { allWpClient } = useStaticQuery(graphql`
    query GET_CLIENTS_IMAGE {
      allWpClient {
        nodes {
          clientsImage {
            image {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
                extension
              }
            }
          }
          id
          title
        }
      }
    }
  `);

  return allWpClient.nodes;
}
