import { graphql, useStaticQuery } from 'gatsby';

export interface CaseData {
  id: string;
  title: string;
  fields: {
    link: string;
    description: string;
    image: {
      localFile: {
        publicURL: string;
        extension: string;
        childImageSharp: any;
      };
    };
  };
}

export function useCases(): CaseData[] {
  const { allWpCase } = useStaticQuery(graphql`
    query GET_CASES {
      allWpCase {
        nodes {
          fields: caseFields {
            link
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
                extension
                publicURL
              }
            }
          }
          id
          title
        }
      }
    }
  `);

  return allWpCase.nodes;
}
